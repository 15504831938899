var arr = [{
	nameEnUs: "Display Cabinet（Split System/Plug-in System）",
	nameZhCn: "制冷陈列柜（分体式/一体式）"
}, {
	nameEnUs: "Commercial Refrigerator",
	nameZhCn: "厨房冰箱"
}, {
	nameEnUs: "Domestic Refrigerator",
	nameZhCn: "家用冰箱"
}, {
	nameEnUs: "Ice Machine ",
	nameZhCn: "制冰机"
}, {
	nameEnUs: "Ice Cream Machine",
	nameZhCn: "冰激凌机"
}, {
	nameEnUs: "Beverage Machine",
	nameZhCn: "饮料机"
}, {
	nameEnUs: "Water Dispenser ",
	nameZhCn: "饮水机"
}, {
	nameEnUs: "Car Refrigerator ",
	nameZhCn: "车载冰箱"
}, {
	nameEnUs: "Medical Freezer ",
	nameZhCn: "医疗冷柜"
}, {
	nameEnUs: "Condensing Unit ",
	nameZhCn: "冷凝机组"
}, {
	nameEnUs: "Data Center Cooling ",
	nameZhCn: "机房散热"
}, {
	nameEnUs: "Electronic Cooling ",
	nameZhCn: "机柜空调"
}, {
	nameEnUs: "Water Cooling/Laster Cooling/Oil Cooling Equipment",
	nameZhCn: "水冷却/激光冷却/油冷却机"
}, {
	nameEnUs: "Projection Equipment Cooling",
	nameZhCn: "投影设备散热"
}, {
	nameEnUs: "Dehumidifier",
	nameZhCn: "除湿机"
}, {
	nameEnUs: "Industrial Heat Pump Drying ",
	nameZhCn: "工业热泵烘干设备"
}, {
	nameEnUs: "Domestic Tumble Dryer/Wash Dryer",
	nameZhCn: "家用干衣机/洗干一体机"
}, {
	nameEnUs: "Charging Station Cooling",
	nameZhCn: "新能源车充电桩散热"
}, {
	nameEnUs: "Commercial Air Conditioner ",
	nameZhCn: "商用空调",
}, {
	nameEnUs: "Others",
	nameZhCn: "其他"
}]

export default arr
