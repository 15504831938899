<template>
	<div class="register">
		<div style="width: 750px;margin: auto;">
			<div class="register-title">{{ $t("register.accountInfo") }}</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm"
				hide-required-asterisk="" style="width: 600px">
				<el-form-item :label="$t('register.companyNameLabel')" prop="companyName">
					<el-input v-model="ruleForm.companyName"></el-input>
				</el-form-item>
			<!-- 	<el-form-item :label="$t('register.companyAddressLabel')" prop="companyAddress">
					<el-input v-model="ruleForm.companyAddress"></el-input>
				</el-form-item> -->
				
				<el-form-item :label="$t('newadd.t.请填写所属行业(选填)')" prop="industry">
					<el-select v-model="ruleForm.industry" :placeholder="$t('newadd.t.请填写所属行业(选填)')">
						 <el-option
						      v-for="(item,index) in workList"
						      :key="index" :label=" $lang(item, 'name') "
						      	:value="$lang(item, 'name') " >
						    </el-option>
					</el-select>
				</el-form-item>
				
				<!-- <el-form-item :label="$t('register.industryLabel')" prop="industry">
					<el-input v-model="ruleForm.industry"></el-input>
				</el-form-item> -->
				<div class="input-line">
					<el-form-item :label="$t('register.nameLabel')" prop="realname">
						<el-input v-model="ruleForm.realname"></el-input>
					</el-form-item>
					<el-form-item :label="$t('register.genderLabel')" prop="sex">
						<el-select v-model="ruleForm.sex" :placeholder="$t('register.pleaseSelectGender')">
							 <el-option
							      v-for="item in sexList"
							      :key="item.id" :label=" $lang(item, 'name') "
							      	:value="item.id  " >
							    </el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-form-item :label="$t('register.positionLabel')" prop="position">
					<el-input v-model="ruleForm.position"></el-input>
				</el-form-item>

				<el-form-item :label="$t('register.phoneLabel')" prop="phone">
					<div class="e-flex e-row-center " style="line-height: 0;">

						<country-code-selector :countryCode.sync="countryvalue" v-if="countryvalue">
						</country-code-selector>

						<el-input v-model="ruleForm.phone"></el-input>
					</div>

				</el-form-item>


				<el-form-item :label="$t('newadd.t.邮编')" >
					<el-input v-model="ruleForm.address.zipCode"></el-input>
				</el-form-item>

				<el-form-item :label="$t('newadd.t.地址')" prop="code">
					<span v-if="ruleForm.address.country">{{ ruleForm.address.country }}</span>
					<span v-if="ruleForm.address.province"> / {{ ruleForm.address.province }}</span>
					<span v-if="ruleForm.address.city"> / {{ ruleForm.address.city }}</span>
					<el-button type="text" style="margin-left: 15px" @click="handleSelectAddressClick">选择</el-button>
				</el-form-item>
				<el-form-item class="full" :label=" $t('newadd.t.详细地址')">
					<el-input type="textarea" v-model="ruleForm.address.address" />
				</el-form-item>
				<el-form-item :label=" $t('newadd.t.请选择货币方式')" prop="currencyId">
					<el-select v-model="ruleForm.currencyId" :placeholder="$t('newadd.t.请选择货币方式')" @change="selectChange">
						<el-option v-for="item in currencyList" :key="item.id" :label=" $lang(item, 'name') "
							:value="item.id  " >
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="next('ruleForm')" style="width: 100%">{{ $t("register.next") }}
					</el-button>
				</el-form-item>
				<el-form-item v-if="!Authorization">
					<router-link to>
						<el-button type="primary" plain @click="$router.back(-1)" style="width: 100%">
							{{ $t("register.prev") }}
						</el-button>
					</router-link>
				</el-form-item>
			</el-form>

			<address-select ref="AddressSelect" @confirm="handleAddressSelect" />

		</div>

	</div>
</template>
<script>
	import {
		mapMutations,
		mapState
	} from 'vuex';
	import {
		getaccoutInfo
	} from "@/api/User";
	import {
		getToken
	} from "@/utils/auth";
	import countryCodeSelector from 'vue-country-code-selector';
	import AddressSelect from "@/components/AddressSelect";
	
	import WorkList from "@/utils/workList.js";
	
	import rules from "../../utils/rule.js";
	import { changeCurrency ,changemodile,changeSex,joinModile} from '@/utils/auth.js';
	export default {
		components: {
			countryCodeSelector,
			AddressSelect
		},
		data() {
	
			return {
				Authorization: getToken(),
				ruleForm: {
					companyName: "",
					// companyAddress: "",
					industry: "",
					realname: "",
					sex: "",
					position: "",
					phone: "",
					address: {
						address: "",
						country: "",
						province: "",
						city: "",
						code: "",
						zipCode: ''
					},
					currencyId: ""

				},
				workList:WorkList, //行业类型
				currencyList: "", //货币类型
				countryvalue: '',
				rules: rules,
				sexList:[{
					id: 1,
					nameEnUs: "Male",
					nameZhCn: "男"
				},{
					id: 2,
					nameEnUs: "Female",
					nameZhCn: "女"
				}]
			};

		},
		async created() {
 
			await this.getCurrency()
			if (this.Authorization) {
				getaccoutInfo().then(res => {
					this.ruleForm = res;
					this.countryvalue = changemodile(res.phone).code;
					this.ruleForm.phone = changemodile(res.phone).phone;
					this.ruleForm.currencyId=changeCurrency(res.currency).id
				}).catch(err => {

				})
			} else {
				this.countryvalue = 86;
			}
		},
		methods: {
			selectChange(){
				this.$forceUpdate();
			},
			
			// 获取货币列表
			getCurrency() {
				return new Promise((resolve, reject) => {
					this.$req.get("/client/im/user/currency/list", {

					}).then(res => {
						this.currencyList = res;
						resolve(res)
					})
				})
			},

			// 城市选择

			handleSelectAddressClick() {
				this.$refs.AddressSelect.show();
			},
			// 城市确认
			handleAddressSelect(obj) {
				let {
					country,
					province,
					city
				} = obj;
				this.ruleForm.address.country = this.$lang(country, "name");
				this.ruleForm.address.province = this.$lang(province, "name");
				this.ruleForm.address.city = this.$lang(city, "name");

				let code = country.ID + "," + province.ID
				if (country.ID == 86) {
					code += ("," + city.ID);
					this.ruleForm.address.city = this.$lang(city, "name");
				}
				console.log(code)
				this.ruleForm.address.code = code;
			},

			next(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.toSubmint();
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			toSubmint() {
				// 深拷贝
				var obj1 = this.ruleForm;
				var obj2 = Object.assign({}, obj1);
				obj2.phone =joinModile(this.countryvalue,obj2.phone).phone;
				obj2.email = this.$route.query.email ? this.$route.query.email : '';
	
				this.$router.push({
					path: "/sentInvoice",
					query: obj2
				});
			},
		},
	};
</script>
<style scoped>
	.register {
		background: #fff;
		padding: 40px 0;
		height: 100vh;
	}

	.register-title {
		text-align: center;
		font-size: 24px;
		margin: 30px auto;
		width: 550px;
	}

	.input-line {
		display: flex;
		justify-content: space-between;
	}
</style>
