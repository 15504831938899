import Vue from '@/main';

export default {
        companyName: [
          {
            required: true,
            message: Vue.$t("register.mesCompanyEmpty"),
            trigger: "blur",
          },
          {
            min: 3,
            max: 50,
            message: Vue.$t("register.mesStrLength"),
            trigger: "blur",
          },
        ],
        companyAddress: [
          {
            required: true,
            message: Vue.$t("register.mesCompanyAddress"),
            trigger: "blur",
          },
          {
            min: 3,
            max: 50,
            message: Vue.$t("register.mesStrLength"),
            trigger: "blur",
          },
        ],
        realname: [
          {
            required: true,
            message: Vue.$t("register.mesName"),
            trigger: "blur",
          },
          {
            min: 2,
            max: 50,
            message: Vue.$t("register.mesStrLength"),
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: Vue.$t("register.mesGender"),
            trigger: "change",
          },
        ],
		
		zipCode: [
		  {
		    required: true,
		    message: Vue.$t("newadd.t.请输入邮编"),
		    trigger: "change",
		  },
		],
		
		
		currencyId: [
		  {
		    required: true,
		    message: Vue.$t("newadd.t.请选择货币方式"),
		    trigger: "change",
		  },
		],
		
		
        position: [
          {
            required: true,
            message: Vue.$t("register.mesPosition"),
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: Vue.$t("register.mesPhone"),
            trigger: "blur",
          },
         { validator: (rule, value, callback) => {
			 Vue.$req.post("/client/im/user/checkPhone",{
				  phone: "+86#"+value
			  }).then(res=>{
				  if(res){
						callback(new Error(Vue.$t("newadd.t.该手机号已经被注册")) );
				  }  
				  callback();
			  })
          }, trigger: "blur" }
          /*{type: 'phone', message: this.$t("register.mesPhoneError"), trigger: 'blur'}*/
        ],
        address: [
          {
            required: true,
            message: Vue.$t("register.messAddress"),
            trigger: "blur",
          },
        ],

	
}
